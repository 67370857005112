var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"container is-fluid"},[_c('page-header',{attrs:{"entity":_vm.$EntitiesName.SheetTemplate,"edit":_vm.edit,"save":_vm.save,"cancel":_vm.cancel,"remove":_vm.remove}},[_c('h1',{staticClass:"title",attrs:{"data-test":"sel-role-header"}},[_c('breadcrumb',{attrs:{"location":"/sheet-templates","text":"Modèle de Fiche Affaire"}}),_vm._v(" "+_vm._s(_vm.sheetTemplate.name)+" ")],1)]),_c('div',{staticClass:"panel"},[_c('div',{staticClass:"panel-heading has-background-primary"},[_c('div',{staticClass:"tabs"},[_c('ul',[_c('li',{class:{'is-active': _vm.currentTab == 0},attrs:{"data-test":"sel-os-infos-tab"},on:{"click":function($event){_vm.currentTab = 0}}},[_c('a',[_vm._v("Informations")])]),_c('li',{class:{'is-active': _vm.currentTab == 1},attrs:{"data-test":"sel-os-prestations-tab"},on:{"click":function($event){_vm.currentTab = 1}}},[_c('a',[_vm._v("Prestations")])])])])]),(_vm.currentTab == 0)?_c('div',{staticClass:"panel-block is-block no-border"},[_c('div',{staticClass:"columns"},[(_vm.sheetTemplate)?_c('div',{staticClass:"column is-half"},[_c('text-field',{attrs:{"label":"Nom","required":""},model:{value:(_vm.sheetTemplate.name),callback:function ($$v) {_vm.$set(_vm.sheetTemplate, "name", $$v)},expression:"sheetTemplate.name"}}),_c('field',{attrs:{"label":"Projet","readonly":""}},[(_vm.sheetTemplate.project)?_c('router-link',{attrs:{"to":'/project/' + _vm.sheetTemplate.project.id}},[_vm._v(_vm._s(_vm.sheetTemplate.project.name))]):_vm._e()],1)],1):_vm._e()])]):_vm._e(),(_vm.currentTab == 1)?_c('div',{staticClass:"panel-block is-block no-border"},[(!_vm.editMode)?_c('flexi-table',{ref:"prestationsList",style:({ width: '100%' }),attrs:{"loader":function (callback) { return callback(_vm.prestations); },"columns":_vm.prestationColumns},scopedSlots:_vm._u([{key:"dataRows",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(item.accountingNatures.length > 0 || item.oscarSubTaskNames.length > 0)?_c('tr',[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(_vm._s(item.categoryName))]),_c('td',[_vm._v(_vm._s(item.subCategoryName))]),_c('td',[_vm._v(_vm._s(item.prestationName))]),_c('td',[_vm._v(_vm._s(item.subPrestationName))]),_c('td',[_c('div',{staticClass:"tags"},_vm._l((item.accountingNatures),function(an){return _c('span',{key:'ans_' + an.id + an.id,staticClass:"tag is-info is-light"},[_vm._v(_vm._s(an.name))])}),0)]),_c('td',[_c('div',{staticClass:"tags"},_vm._l((item.oscarSubTaskNames),function(ostn){return _c('span',{key:'ostn_' + ostn.id + ostn.id,staticClass:"tag is-success is-light"},[_vm._v(_vm._s(ostn.subTaskName))])}),0)])]):_vm._e(),(false)?_c('tr'):_vm._e()]}}],null,false,1391529874)}):_vm._e(),(_vm.editMode)?_c('b-table',{attrs:{"data":this.prestations,"draggable":"","draggable-column":""},on:{"dragstart":_vm.dragstart,"drop":_vm.drop,"dragover":_vm.dragover,"dragleave":_vm.dragleave}},[_c('b-table-column',{attrs:{"label":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.ordering))]}}],null,false,48899441)}),_c('b-table-column',[_c('div',{staticClass:"selectable grab"},[_c('font-awesome-icon',{attrs:{"icon":"grip-horizontal"}})],1)]),_c('b-table-column',{attrs:{"label":"Catégorie"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.categoryName))]}}],null,false,97481576)}),_c('b-table-column',{attrs:{"label":"Sous-catégorie"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.subCategoryName))]}}],null,false,3738595052)}),_c('b-table-column',{attrs:{"label":"Prestation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.prestationName))]}}],null,false,1640948101)}),_c('b-table-column',{attrs:{"label":"Sous-pestation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.subPrestationName))]}}],null,false,328621857)}),_c('b-table-column',{attrs:{"label":"Natures comptables"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"tags"},[_vm._l((row.accountingNatures),function(an,index){return [_c('many2one-field',{key:'an_' + row.prestationId + an.id + index,attrs:{"value":an,"fetch":_vm.$Api.AccountingNature.fetchAvailableAccountingNatures.bind(this, row.sheetTemplateId, row.prestationId, _vm.accountingNaturesToExclude(row.prestationId)),"reference":"name","entity":_vm.$EntitiesName.AccountingNature,"columns":{ name: 'Nom' , description: 'Description'},"hasFilter":true,"inline":false,"edit":true,"deleteonly":"","dataTest":'delete-an-' + row.subPrestationName + '-' + an.name},on:{"input":function($event){return _vm.updateAcountingNature($event, row.accountingNatures, index)}}})]}),_c('many2one-field',{key:'an-selector-' + _vm.key,attrs:{"value":row.accountingNatures,"fetch":_vm.$Api.AccountingNature.fetchAvailableAccountingNatures.bind(this, row.sheetTemplateId, row.prestationId, _vm.accountingNaturesToExclude(row.prestationId)),"reference":"name","entity":_vm.$EntitiesName.AccountingNature,"columns":{ name: 'Nom' , description: 'Description'},"hasFilter":true,"inline":false,"edit":true,"required":"","dataTest":'add-an-' + row.subPrestationName,"hiddenSelection":""},on:{"input":function($event){return _vm.addAcountingNatures($event, row.accountingNatures)}}})],2)]}}],null,false,3278000930)}),_c('b-table-column',{attrs:{"label":"Sous-tâches OSCAR"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"tags"},[_vm._l((row.oscarSubTaskNames),function(oscarSubTaskName,index){return [_c('many2one-field',{key:row.prestationId + oscarSubTaskName.id + index,attrs:{"value":oscarSubTaskName,"fetch":_vm.$Api.AccountingNature.fetchAvailableAccountingNatures.bind(this, row.sheetTemplateId, row.prestationId, _vm.accountingNaturesToExclude(row.prestationId)),"reference":"subTaskName","entity":_vm.$EntitiesName.AccountingNature,"columns":{ name: 'Nom' , description: 'Description'},"hasFilter":true,"inline":false,"edit":true,"deleteonly":"","dataTest":'delete-ost-' + row.subPrestationName + '-' + oscarSubTaskName.subTaskName},on:{"input":function($event){return _vm.updateSubTaskName($event, row.oscarSubTaskNames, index)}}})]}),_c('many2one-field',{key:'st-selector-' + _vm.key,attrs:{"value":row.oscarSubTaskNames,"fetch":_vm.$Api.OscarSubTask.fetchOscarSubTaskNames,"reference":"subTaskName","entity":_vm.$EntitiesName.OscarSubTaskName,"columns":{ taskName: 'Nom tâche' , subTaskName: 'Nom sous-tâche'},"hasFilter":true,"inline":false,"edit":true,"required":"","dataTest":'add-ost-' + row.subPrestationName,"hiddenSelection":""},on:{"input":function($event){return _vm.addSubTaskNames($event, row.oscarSubTaskNames)}}})],2)]}}],null,false,2205960545)})],1):_vm._e()],1):_vm._e()])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }