<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header
        :entity="$EntitiesName.SheetTemplate"
        :edit="edit"
        :save="save"
        :cancel="cancel"
        :remove="remove"
      >
        <h1 class="title" data-test="sel-role-header">
          <breadcrumb
            location="/sheet-templates"
            text="Modèle de Fiche Affaire"
          ></breadcrumb>
          {{ sheetTemplate.name }}
        </h1>
      </page-header>

      <div class="panel">
        <div class="panel-heading has-background-primary">
          <div class="tabs">
            <ul>
              <li :class="{'is-active': currentTab == 0}" data-test="sel-os-infos-tab" @click="currentTab = 0">
                <a>Informations</a>
              </li>
              <li :class="{'is-active': currentTab == 1}" data-test="sel-os-prestations-tab" @click="currentTab = 1">
                <a>Prestations</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="panel-block is-block no-border" v-if="currentTab == 0">
          <div class="columns">
            <div class="column is-half" v-if="sheetTemplate">
              <text-field
                label="Nom"
                v-model="sheetTemplate.name"
                required
              ></text-field>
              <field label="Projet" readonly>
                <router-link v-if="sheetTemplate.project" :to="'/project/' + sheetTemplate.project.id">{{ sheetTemplate.project.name }}</router-link>
              </field>
            </div>
          </div>
        </div>
        <div class="panel-block is-block no-border" v-if="currentTab == 1">
          <flexi-table
            :loader="(callback) => callback(prestations)"
            :columns="prestationColumns"
            ref="prestationsList"
            :style="{ width: '100%' }"
            v-if="!editMode"
          >
            <template slot="dataRows" slot-scope="{ item, index }">
              <tr v-if="item.accountingNatures.length > 0 || item.oscarSubTaskNames.length > 0">
                <td>{{ index + 1 }}</td>
                <td>{{ item.categoryName }}</td>
                <td>{{ item.subCategoryName }}</td>
                <td>{{ item.prestationName }}</td>
                <td>{{ item.subPrestationName }}</td>
                <td>
                  <div class="tags">
                    <span v-for="(an) in item.accountingNatures" :key="'ans_' + an.id + an.id" class="tag is-info is-light">{{ an.name }}</span>
                  </div>
                </td>
                <td>
                  <div class="tags">
                    <span v-for="(ostn) in item.oscarSubTaskNames" :key="'ostn_' + ostn.id + ostn.id" class="tag is-success is-light">{{ ostn.subTaskName }}</span>
                  </div>
                </td>
              </tr>
              <tr v-if="false"></tr>
            </template>
          </flexi-table>

          <b-table
            v-if="editMode"
            :data="this.prestations"
            draggable
            draggable-column
            @dragstart="dragstart"
            @drop="drop"
            @dragover="dragover"
            @dragleave="dragleave">
              <b-table-column v-slot="{ row }" label="">{{ row.ordering }}</b-table-column>
              <b-table-column><div class="selectable grab"><font-awesome-icon icon="grip-horizontal"/></div></b-table-column>
              <b-table-column v-slot="{ row }" label="Catégorie">{{ row.categoryName }}</b-table-column>
              <b-table-column v-slot="{ row }" label="Sous-catégorie">{{ row.subCategoryName }}</b-table-column>
              <b-table-column v-slot="{ row }" label="Prestation">{{ row.prestationName }}</b-table-column>
              <b-table-column v-slot="{ row }" label="Sous-pestation">{{ row.subPrestationName }}</b-table-column>
              <b-table-column v-slot="{ row }" label="Natures comptables">
                <div class="tags">
                    <template v-for="(an, index) in row.accountingNatures">
                    <many2one-field
                      :key="'an_' + row.prestationId + an.id + index"
                      :value="an"
                      @input="updateAcountingNature($event, row.accountingNatures, index)"
                      :fetch="$Api.AccountingNature.fetchAvailableAccountingNatures.bind(this, row.sheetTemplateId, row.prestationId, accountingNaturesToExclude(row.prestationId))"
                      reference="name"
                      :entity="$EntitiesName.AccountingNature"
                      :columns="{ name: 'Nom' , description: 'Description'}"
                      :hasFilter="true"
                      :inline="false"
                      :edit="true"
                      deleteonly
                      :dataTest="'delete-an-' + row.subPrestationName + '-' + an.name"
                    ></many2one-field>
                    </template>

                    <many2one-field
                      :value="row.accountingNatures"
                      @input="addAcountingNatures($event, row.accountingNatures)"
                      :fetch="$Api.AccountingNature.fetchAvailableAccountingNatures.bind(this, row.sheetTemplateId, row.prestationId, accountingNaturesToExclude(row.prestationId))"
                      reference="name"
                      :entity="$EntitiesName.AccountingNature"
                      :columns="{ name: 'Nom' , description: 'Description'}"
                      :hasFilter="true"
                      :inline="false"
                      :edit="true"
                      :key="'an-selector-' + key"
                      required
                      :dataTest="'add-an-' + row.subPrestationName"
                      hiddenSelection
                    ></many2one-field>
                  </div>
              </b-table-column>
              <b-table-column v-slot="{ row }" label="Sous-tâches OSCAR">
                <div class="tags">
                    <template v-for="(oscarSubTaskName, index) in row.oscarSubTaskNames">
                    <many2one-field
                      :key="row.prestationId + oscarSubTaskName.id + index"
                      :value="oscarSubTaskName"
                      @input="updateSubTaskName($event, row.oscarSubTaskNames, index)"
                      :fetch="$Api.AccountingNature.fetchAvailableAccountingNatures.bind(this, row.sheetTemplateId, row.prestationId, accountingNaturesToExclude(row.prestationId))"
                      reference="subTaskName"
                      :entity="$EntitiesName.AccountingNature"
                      :columns="{ name: 'Nom' , description: 'Description'}"
                      :hasFilter="true"
                      :inline="false"
                      :edit="true"
                      deleteonly
                      :dataTest="'delete-ost-' + row.subPrestationName + '-' + oscarSubTaskName.subTaskName"
                    ></many2one-field>
                    </template>

                    <many2one-field
                      :value="row.oscarSubTaskNames"
                      @input="addSubTaskNames($event, row.oscarSubTaskNames)"
                      :fetch="$Api.OscarSubTask.fetchOscarSubTaskNames"
                      reference="subTaskName"
                      :entity="$EntitiesName.OscarSubTaskName"
                      :columns="{ taskName: 'Nom tâche' , subTaskName: 'Nom sous-tâche'}"
                      :hasFilter="true"
                      :inline="false"
                      :edit="true"
                      :key="'st-selector-' + key"
                      required
                      :dataTest="'add-ost-' + row.subPrestationName"
                      hiddenSelection
                    ></many2one-field>
                  </div>
              </b-table-column>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "sheetTemplate",
  props: ["id"],
  computed: {
    ...mapGetters({
      editMode: "App/isEdition",
    }),
  },
  data() {
    return {
      currentTab: 0,
      sheetTemplate: {},
      prestations: [],
      projectsColumns: {
        name: 'Projet',
        'region.name': 'Région',
        'region.direction.name': 'Direction'
      },
      subPrestationsColumns: {
        'prestation.category.parent.name': 'Catégorie',
        'prestation.category.name': 'Sous-catégorie',
        'prestation.name': 'Prestation',
        'oscarColumn.name': 'Sous-prestation',
        'phase.name': 'Phase',
        'unit.name': 'Unité'
      },
      prestationColumns: [
        {
          title: 'Catégorie',
          name: 'category',
        },
        {
          title: 'Sous-catégorie',
          name: 'sub-category',
        },
        {
          title: 'Prestation',
          name: 'prestation',
        },
        {
          title: 'Sous-prestation',
          name: 'sub-prestation',
        },
        {
          title: 'Natures comptables',
          name: 'accounting-nature',
        },
        {
          title: 'Sous-tâches OSCAR',
          name: 'oscar-substaks-names',
        },
      ],
      key: 0,
      draggingRow: null,
      draggingRowIndex: null,
    };
  },
  watch: {
    prestations() {
      if(this.$refs.prestationsList) {
        this.$refs.prestationsList.fetch()
      }
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      axios.get(`/sheet-templates/${this.id}`).then((r) => {
        this.sheetTemplate = r.data;
        this.$store.dispatch("App/setEditing", false);

        this.fetchPrestations()
      });
    },
    fetchPrestations() {
      this.$Api.SheetTemplate.fetchPrestationSheetTemplates(this.sheetTemplate.id).then((d) => {
        this.prestations = d
        this.setOrderOnPrestations()
      })
    },
    remove() {
      return this.$awn.confirm(
        "Êtes-vous sûr de vouloir supprimer cet élément ?",
        () =>
          axios.delete(`/sheet-templates/${this.id}`).then((res) => {
            if (res.data.success) {
              this.$router.replace("/sheet-templates");
            }
          })
      );
    },
    save() {
      let prestations = []
      this.prestations.forEach((p) => {
        for(let i = 0; i < p.subPrestations.length; i++) {
          let prestation = { ...p }
          delete prestation.subPrestations
          delete prestation.ids

          prestation.id = p.ids[i]
          prestation.subPrestation = p.subPrestations[i]

          prestations.push(prestation)
        }
      })

      const sheetTemplate = {
        ...this.sheetTemplate,
        prestationSheetTemplates: prestations
      }
      return axios
        .put(`/sheet-templates/${this.id}`, sheetTemplate)
        .then((response) => {
          if (response.data.success) {
            this.fetchData();
          }
        });
    },
    edit() {
      this.$store.dispatch("App/setEditing", true);
    },
    cancel() {
      this.fetchData();
    },
    updatePrestations(val) {
      this.prestations = val
    },
    accountingNaturesToExclude(prestationId) {
      let ans = []
      this.prestations.forEach(p => {
        p.accountingNatures.forEach(an => {
          if(p.prestationId !== prestationId && !ans.includes(an.id)) {
            ans.push(an.id)
          }
        })
      })
      return ans
    },
    addAcountingNatures(ans, array) {
      ans.forEach(an => {
        if(!array.some(e => e.id === an.id)) {
          array.push(an)
        }
        this.key += 1
      })
    },
    updateAcountingNature(an, array, index) {
      if(!an) {
        // delete
        array.splice(index, 1)
      } else {
        // update
        array[index] = an
      }
    },
    addSubTaskNames(stns, array) {
      stns.forEach(stn => {
        if(!array.includes(stn)) {
          array.push(stn)
        }
        this.key += 1
      })
    },
    updateSubTaskName(stn, array, index) {
      if(!stn) {
        // delete
        array.splice(index, 1)
      } else {
        // update
        array[index] = stn
      }
    },
    dragstart(payload) {
      this.draggingRow = payload.row
      this.draggingRowIndex = payload.index
      payload.event.dataTransfer.effectAllowed = 'copy'
    },
    dragover(payload) {
      payload.event.dataTransfer.dropEffect = 'copy'
      if(this.draggingRowIndex > payload.index) {
        payload.event.target.closest('tr').classList.add('add-before')
      } else {
        payload.event.target.closest('tr').classList.add('add-after')
      }
      payload.event.preventDefault()
    },
    dragleave(payload) {
      payload.event.target.closest('tr').classList.remove('add-before')
      payload.event.target.closest('tr').classList.remove('add-after')
      payload.event.preventDefault()
    },
    drop(payload) {
      payload.event.target.closest('tr').classList.remove('add-before')
      payload.event.target.closest('tr').classList.remove('add-after')
      const droppedOnRowIndex = payload.index
      let data = this.prestations
      if(this.draggingRowIndex > droppedOnRowIndex) {
        data.splice(this.draggingRowIndex, 1)
        data.splice(droppedOnRowIndex, 0, this.draggingRow)
      } else {
        data.splice(this.draggingRowIndex, 1)
        data.splice(droppedOnRowIndex, 0, this.draggingRow)
      }
      this.setOrderOnPrestations()
    },
    setOrderOnPrestations() {
      this.prestations.forEach((p, idx) => {
        p.ordering = idx + 1
      })
    }
  },
};
</script>

<style scoped lang="scss">
.panel-heading {
  padding: 0em 0.75em;
  .tabs {
    ul {
      border-bottom: none;
    }
    li {
      width: 25%;
      a {
        color: #fff;
        border-bottom: none;
        padding: 1em 0em;
      }
      &.is-active a {
        color: #000;
        background-color: #fff;
      }
    }
  }
}

.is-fullwidth {
  width: 100%;
}

.inline-form {
  display: inline;
}

tr {
  td.filterable {
    width: 12%;
  }
}

.tags {
  // display: flex;
  // flex-wrap: wrap;
  &>* {
    width: initial;
  }
}

.tag {
  margin-right: 3px;
  margin-bottom: 3px;
}
.selectable {
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
}
</style>